@import '../variables.less';

.first-page-content {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
        "triangle square circle"
        "greeting greeting greeting"
        "learn learn learn";
    width: auto;
    max-width: 85%;
    margin: @title-margin auto;
    align-items: flex-start;
    justify-items: start;
    grid-column-gap: 45px;
    grid-row-gap: 5px;
    padding: 0 10px;
    overflow: auto;
    justify-items: center;

    @media (max-width: 995px) {
        max-width: calc(clamp(60%, 85%, 95%));
    }

    @media (max-width: 550px) {
        grid-column-gap: 5px;
    }

    @media (max-width: 470px) {
        max-width: none;
        width: 90%;
        margin: 60px auto auto auto;
    }

    @media (max-width: 350px) {
        width: 95%;
    }

}

#learn-text {
    font-weight: 700;
}

.learn-more {
    font-size: 24px;
    grid-area: learn;
    color: @black;
    background-color: rgba(52, 199, 89, 1);
    border: 2px solid @black;
    box-sizing: border-box;
    outline: 4px solid rgba(52, 199, 89, 1);
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.5s ease;
    margin-bottom: 30px;

    &:hover {
        animation: pulse 1s 1;
    }

    @media (max-width: 1200px) {
        font-size: 18px;
        padding: 10px;

    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(52, 199, 89, 0.7);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(52, 199, 89, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(52, 199, 89, 0);
    }
}


.triangle {
    grid-area: triangle;
}

.square {
    grid-area: square;
}

.circle {
    grid-area: circle;
}

.land {
    fill: #1616182b;
    stroke: #161618;
    stroke-width: 1.25;
}


