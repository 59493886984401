@import '../variables.less';

.menu-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: rgba(51, 51, 51, 0.97);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    z-index: 10;
}
.menu-toggle-visible {
    display: block !important;
}

.menu-a-icon {
    height: 20px;
    margin-left: -80px;
}

.menu-bar-start {
    width: 140px;
}

.menu-bar-start,
.search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-items {
    margin-left: -15px;
    display: flex;
    gap: 60px;
    flex-grow: 1;
    justify-content: center;
}

.menu-items span:hover {
    color: white;
}

.menu-items span {
    cursor: pointer;
}

#menu-contact .fas.fa-envelope {
    padding-right: 3px;
}

#menu-resume .fas.fa-file-arrow-down {
    padding-right: 3px;
}

@media (max-width: 1150px) {
    .menu-items {
        gap: 50px;
        margin-right: 60px;
    }
}



@media (max-width: 1000px) {
    .menu-items {
        gap: 35px;
        margin-right: 70px;
    }
}




@media (max-width: 900px) {
    .menu-items {
        gap: 20px;
    }
    /* clear menu items on search expansion for mobile */
    .menu-items-cleared {
        max-width: 0;
        overflow: hidden;
        transition: max-width 0.5s ease-in-out;
    }
}


/* Hide menu items and show hamburger menu */
@media (max-width: 900px) {
    .menu-items {
        display: none;
    }

    .menu-toggle {
        display: block;
    }

    .search-container {
        width: auto;
    }

    .search-bar:focus {
        width: 100%;
    }

    .right-section {
        margin-left: auto;
    }
}

 /* hamburger and search */
.right-section {
    display: flex;
    align-items: center;
}

.menu-toggle {
    display: none;
    cursor: pointer;
    padding: 0 15px;
    font-size: 20px;
}

@media (max-width: 900px) {
    .menu-items {
        display: none;
    }

    .menu-toggle {
        display: block;
    }
}


/* keyframes for dropdown animation */
@keyframes slideIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

/* full-screen dropdown menu styles */
.dropdown-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.93);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    z-index: 11;
    padding: 0;
    box-sizing: border-box;
    animation-fill-mode: forwards;
}

.slide-in {
    animation: slideIn 0.5s ease forwards;
}

.slide-out {
    animation: slideOut 0.5s ease forwards;
}

.dropdown-item {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    background-color: #333333;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    transition: background-color 0.3s;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.dropdown-item:hover {
    cursor: pointer;
    background-color: #DDDDDF;
    color: black;
}

.menu-toggle {
    z-index: 12;
}






@keyframes overlaySlideIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Keyframes for overlay slide-out animation */
@keyframes overlaySlideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}



.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 9;
    animation-fill-mode: forwards;
}

.overlay-visible {
    animation: overlaySlideIn 0.5s ease forwards;
}

.overlay:not(.overlay-visible) {
    animation: overlaySlideOut 0.5s ease forwards;
}
