@import '../variables.less';

.greeting-container {
    padding: 10px 3px;
    position: relative;
    display: flex;
    align-items: top;
    flex-wrap: wrap;
}

.greeting-outer-container {
    position: relative;
    width: 100%;
}

.greeting {
    // background-color: red;
    margin-bottom: 25px;
    min-height: 222px;
    border-radius: 10px;
    width: 100%;
    // font-size: 84px;
    grid-area: greeting;
    font-weight: bold;
    color: white;
    text-align: left;

    @media (max-width: 675px) {
        margin-bottom: 10px;
    }

    @media (max-width: 575px) {
        margin-bottom: 5px;
    }
}



.cursorBlink {
    display: inline-block;
    height: 1em;
    width: 0.7em;
    background: #0C0C0C;
    margin-left: 2px;
    animation: 1s blink step-end infinite;
}

@keyframes blink {

    from,
    to {
        background: transparent;
    }

    50% {
        background: white;
    }
}



// morning message
.morning-greeting {
    font-size: 84px;
    padding-top: 30px;

    @media (max-width: 1487px) {
        padding-top: 0px;
    }

    @media (max-width: 830px) {
        font-size: 76px;
    }

    @media (max-width: 663px) {
        font-size: 68px;
    }

    @media (max-width: 597px) {
        font-size: 64px;
    }

    @media (max-width: 565px) {
        padding-top: 20px;
        font-size: 58px;
    }

    @media (max-width: 514px) {
        font-size: 54px;
    }

    @media (max-width: 482px) {
        font-size: 50px;
    }

    @media (max-width: 425px) {
        font-size: 56px;
        padding-top: 0px;
    }

    @media (max-width: 383px) {
        font-size: 50px;
    }

    @media (max-width: 320px) {
        font-size: 47px;
    }
}

// afternoon message
.afternoon-greeting {
    font-size: 84px;
    padding-top: 30px;

    @media (max-width: 1487px) {
        padding-top: 0px;
    }

    @media (max-width: 847px) {
        font-size: 78px;
    }

    @media (max-width: 798px) {
        font-size: 72px;
    }

    @media (max-width: 648px) {
        font-size: 68px;
    }

    @media (max-width: 611px) {
        font-size: 65px;
    }

    @media (max-width: 585px) {
        font-size: 62px;
    }

    @media (max-width: 559px) {
        font-size: 58px;
    }

    @media (max-width: 525px) {
        padding-top: 20px;
        font-size: 52px;
    }

    @media (max-width: 475px) {
        padding-top: 25px;
        font-size: 50px;
    }

    @media (max-width: 432px) {
        padding-top: 35px;
        font-size: 45px;
    }

    @media (max-width: 391px) {
        padding-top: 35px;
        font-size: 42px;
    }

    @media (max-width: 369px) {
        padding-top: 35px;
        font-size: 38px;
    }
}


// evening message
.evening-greeting {
    font-size: 84px;
    padding-top: 30px;

    @media (max-width: 1487px) {
        padding-top: 0px;
    }

    @media (max-width: 830px) {
        font-size: 76px;
    }

    @media (max-width: 663px) {
        font-size: 68px;
    }

    @media (max-width: 597px) {
        font-size: 64px;
    }

    @media (max-width: 565px) {
        font-size: 58px;
        padding-top: 20px;
    }

    @media (max-width: 514px) {
        font-size: 54px;
    }

    @media (max-width: 482px) {
        font-size: 50px;
    }

    @media (max-width: 425px) {
        font-size: 56px;
        padding-top: 0px;
    }

    @media (max-width: 383px) {
        font-size: 50px;
    }
}
