@import '../variables.less';

#awards-title {
    text-align: left;
    margin-top: -5px;
    margin-bottom: 35px;
}

.awards-container {
    width: 85%;
    margin: auto;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.award-grid-container .award-grid-item:nth-child(2) {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.award-grid-container .award-grid-item:nth-child(2)::before {
    left: -20px;
}

.award-grid-container .award-grid-item:nth-child(2)::after {
    right: -20px;
}

.award-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    align-items: start;
}

.award-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.award-grid-item img {
    width: 100%;
    height: auto;
}

.award-grid-item p {
    text-align: left;
    margin-top: 10px;
}

.award-headline {
    margin-top: 15px;
    font-size: 20px;
    margin-bottom: 5px;
    text-align: center;
}

.award-subhead {
    font-weight: 500;
    font-size: 17px;
}

.award-word {
    font-weight: 300;
}

.award-quote {
    font-weight: 300;
    color: #4c4c4c;
    font-size: 14px;
}

.award-quote ul {
    padding-left: 12.5px;
}

.award-emphasis {
    font-weight: 500;
}


// animation
.award-grid-item {
    opacity: 0;
    transform: translateX(150px) scale(0.1);
    transition: opacity 1s ease-in, transform 1s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.award-grid-item.visible {
    opacity: 1;
    transform: translateX(0) scale(1);
}


// mobile layout
@media (max-width: 900px) {
    .award-grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        justify-content: space-between;
        height: 550px;
    }

    .award-grid-item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .award-grid-item .award-item-content,
    .award-grid-item:not(:first-child) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    .award-grid-item img {
        width: 50%;
        object-fit: cover;
    }

    .award-text-content,
    .award-grid-item:not(:first-child) .award-text-content {
        width: 50%;
        padding: 0 10px;
    }

    .award-quote {
        display: none;
    }

    .award-headline {
        font-size: 18px;
        text-align: left;
    }

    .award-subhead {
        font-size: 15px;
    }

    .award-grid-container .award-grid-item:nth-child(2)::before,
    .award-grid-container .award-grid-item:nth-child(2)::after {
        width: 0px;
        background-color: none;
    }

}

@media (max-width: 650px) {
    .award-grid-container {
        justify-content: center;
    }

    .award-grid-item img {
        padding: 20px 0px;
    }

}






@media (max-width: 550px) {
    .award-grid-container {
        height: 550px;
    }

    .award-headline {
        display: none;
    }

    .award-subhead {
        display: none;
    }
    .award-grid-item img {
        width: 100%;
        padding: 0px 60px;
        object-fit: cover;
    }

}

@media (max-width: 500px) {
    .award-grid-item img {
        padding: 0px 45px;
    }
}


@media (max-width: 465px) {
    .award-grid-item img {
        padding: 0px 25px;
    }
}

@media (max-width: 435px) {
    .award-grid-item img {
        padding: 0px 10px;
    }
}

@media (max-width: 400px) {
    .award-grid-item img {
        padding: 0px 5px;
    }
}

@media (max-width: 380px) {
    .award-grid-item img {
        padding: 5px 0px;
    }
}

@media (max-width: 350px) {
    .award-grid-item img {
        padding: 10px 0px;
    }
}

@media (max-width: 330px) {
    .award-grid-item img {
        padding: 20px 0px;
    }
}
