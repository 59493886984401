@import '../variables.less';

.button-contact,
.button-resume {
    position: fixed;
    right: 50px;
    border: 3.5px solid @blue;
    cursor: pointer;
    z-index: 10;
    width: auto;
    height: 70px;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    bottom: 30px;
    padding: 0 30px;
    box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    max-width: 50px;
    transition: max-width 0.5s;

    @media (max-width: 660px) {
        border: 2.5px solid @blue;
        height: 55px;
        bottom: 20px;
        padding: 0 25px;
    }
}

.button-contact,
.button-resume {
    right: 30px;

    @media (max-width: 660px) {
        right: 15px;
    }
}


.button-resume {
    bottom: 120px;

    @media (max-width: 660px) {
        bottom: 90px;
    }
}



.button-contact i,
.button-resume i {
    font-size: 28px;
    font-weight: bold;
    color: white;
}

/* Hover effects */
.button-resume span,
.button-contact span {
    color: white;
    font-weight: bold;
    font-family: Roboto;
    display: none;
    white-space: nowrap;
    margin-left: 15px;
}

.button-resume:hover,
.button-contact:hover {
    max-width: 150px;
}

.button-contact:hover,
.button-resume:hover {
    opacity: 1 !important;
}

.button-resume:hover span,
.button-contact:hover span {
    display: inline;
}

.float-text{
    font-size: 16px;
}
