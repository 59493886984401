@import './variables.less';


h1,
h2,
h3 {
    font-family: @roboto, Arial, sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: black;

    @media (max-width: 600px) {
        font-size: 42px;
    }
}

body,
p,
span {
    font-family: @roboto, Arial, sans-serif;
    font-weight: 400;
}
p, h2 {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
