@import '../variables.less';

#experience-title {
    text-align: left;
    margin-top: -5px;
    margin-bottom: 20px;
    color: white;
}

.experience-container {
    width: 85%;
    margin: auto;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    @media (max-width: 768px) {
        height: 1100px;
    }
}

body {
    --color: rgba(30, 30, 30);
    --bgColor: rgba(245, 245, 245);
    min-height: 100%;
    display: grid;
    align-content: center;
    color: var(--color);
    background: var(--bgColor);
}

#timeline-list {
    --col-gap: 2rem;
    --row-gap: 1.25rem;
    --line-w: .4rem;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-gap);
    list-style: none;
    width: min(100%);
    margin-inline: auto;
}

/* line */
#timeline-list::before {
    content: "";
    grid-column: 1;
    grid-row: 1 / span 9;
    background: #F5F5F5;
    border-radius: calc(var(--line-w) / 2);
}


@media screen and (max-width: 640px) {
    #timeline-list::before {
        content: "";
        grid-column: 1;
        grid-row: 1 / span 18;
        background: #F5F5F5;
        border-radius: calc(var(--line-w) / 2);
    }
}



/* row gaps */
#timeline-list li:not(:last-child) {
    margin-bottom: var(--row-gap);
}

/* card */
#timeline-list li {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-column: 2;
    --inlineP: 1.5rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
}

/* headline */
#timeline-list li .headline {
    margin-bottom: 0px;
    --headlineH: 2.25rem;
    height: var(--headlineH);
    margin-inline: calc(var(--inlineP) * -1);
    
    text-align: center;
    background-color: var(--accent-color);

    color: white;
    font-size: 1rem;
    font-weight: 700;

    display: grid;
    place-content: center;
    position: relative;

    border-radius: calc(var(--headlineH) / 2) 0 0 calc(var(--headlineH) / 2);
}

/* headline flap */
#timeline-list li .headline::before {
    content: "";
    width: var(--inlineP);
    aspect-ratio: 1;
    background: var(--accent-color);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
}

.subhead {
    color: black;
}

/* circle */
#timeline-list li .headline::after {
    content: "";
    position: absolute;
    width: 2rem;
    aspect-ratio: 1;
    background: #161618;
    border: 0.3rem solid var(--accent-color);
    border-radius: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* #timeline-list li .headline::after,
#timeline-list li .circle {
    cursor: pointer;
} */

/* title descr */
#timeline-list li .subhead,
#timeline-list li .exp-desc {
    background: var(--bgColor);
    position: relative;
    padding-inline: 1rem;
}

#timeline-list li .subhead {
    padding-block-start: 0.75rem;
    padding-block-end: 0.2rem;
    font-weight: 500;
}

#timeline-list li .exp-desc {
    padding-block-end: 1rem;
    font-weight: 300;
}


@media (min-width: 40rem) {
    #timeline-list {
        grid-template-columns: 1fr var(--line-w) 1fr;
    }

    #timeline-list::before {
        grid-column: 2;
    }

    #timeline-list li:nth-child(odd) {
        grid-column: 1;
    }

    #timeline-list li:nth-child(even) {
        grid-column: 3;
    }

    /* start second card */
    #timeline-list li:nth-child(2) {
        grid-row: 2/4;
    }

    #timeline-list li:nth-child(odd) .headline::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        left: 0;
    }

    #timeline-list li:nth-child(odd) .headline::after {
        transform: translate(-50%, -50%);
        left: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }

    #timeline-list li:nth-child(odd) .headline {
        border-radius: 0 calc(var(--headlineH) / 2) calc(var(--headlineH) / 2) 0;
    }
}

.headline,
.subhead,
.exp-desc {
    grid-column: 1 / -1;
}

.timeline-item {
    position: relative;
}

.circle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(-1 * (var(--col-gap) + var(--line-w) / 2 + 2rem));
}
