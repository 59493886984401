@import '../variables.less';

#tips-title {
    color: white;
    text-align: left;
    margin-top: -5px;
    margin-bottom: 15px;
}

.tips-container {
    width: 85%;
    color: white;
    margin: auto;
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.tips-description {
    margin-bottom: 20px;
}

.icon-container {
    text-align: center;
    margin-bottom: 10px;
}

.tips-icon {
    font-size: 40px;
}

.tips-input {
    font-weight: normal;
    font-size: 18px;
    width: 100%;
    height: 250px;
    margin: 10px 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: white;
    resize: none;
    border: 3px solid @blue;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 610px) {
        height: 100px;
    }

    &:focus {
        outline: 2px solid @blue;
    }
}


.tips-input::placeholder {
    color: @blue;
    font-weight: bold; 
}

.name-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.tips-first,
.tips-last,
.tips-email,
.tips-phone {
    width: calc(50% - 10px);
    font-size: 16px;
    font-family: 'Roboto';
    height: 40px;
    margin: 10px 0;
    padding: 8px 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    resize: none; // Prevent resizing of the textarea
    overflow-y: auto; // Allow scrolling vertically when necessary, but no scrollbar displayed
    &:focus {
        outline: 2px solid @blue;
    }
    &::-webkit-scrollbar {
        display: none; // This will hide the scrollbar in WebKit browsers (e.g., Chrome and Safari)
    }
    -ms-overflow-style: none; // This hides the scrollbar for IE 10+ and Edge
    scrollbar-width: none; // This will hide the scrollbar in Firefox
}

.contact-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}



.tips-submit {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    background-color: @blue;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: bold;

    @media (max-width: 610px) {
        margin: 10px 0px 0px 0px;
        font-size: 18px;
    }
}

.tips-submit:hover {
    background-color: @hover-blue;
}

.invalid-input {
    border: 2.4px solid @red;
}


.tips-input::placeholder {
    color: @blue;
    font-size: 18px;
    font-family: 'Roboto';
}




@media (max-width: 610px) {
    .tips-input::placeholder {
        font-size: 14px;
    }
}

.invalid-input::placeholder {
    color: @red;
    font-weight: bold;
    opacity: 1;
}


@media (max-width: 610px) {

    .name-container,
    .contact-container {
        flex-direction: column;
        gap: 0px;
    }

    .tips-first,
    .tips-last,
    .tips-email,
    .tips-phone {
        width: 100%;
    }
}

.info-container {
    position: relative;
    display: inline-block;
}

.fa-circle-info {
    margin-left: 3px;
    font-size: 17px;
    vertical-align: top;

}

.tooltip {
    color: white;
    font-size: 13px;
    position: absolute;
    bottom: 30%;
    left: calc(50% + 1px);
    transform: translateX(-50%) translateY(-15px);
    border: 1px solid #ccc;
    background-color: black;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    margin-bottom: 12px;
}


.tooltip::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
    box-shadow: none;
}


@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-10px);
    }

    50% {
        transform: translateX(10px);
    }

    75% {
        transform: translateX(-10px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake-animation {
    animation: shake 0.5s ease-in-out;
}


// form submission success
.success-container {
    display: flex;
    flex-direction: column;
    height: 450px;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.success-top {
    margin: 60px 25% 0px 25%;
    border-radius: 30px 30px 0px 0px;
    background-color: @blue;
    color: white;

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 630px) {
        margin: 60px 15% 0px 15%;
    }

    @media (max-width: 500px) {
        margin: 60px 5% 0px 5%;
    }

    @media (max-width: 380px) {
        margin: 40px 0% 0px 0%;
    }
}

.submission-success-indicator {
    width: 85px;
    height: 85px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 660px) {
        width: 75px;
        height: 75px;
    }
    
}

.submission-thank-you {
    color: rgb(76, 76, 76);
    font-size: 20px;
    padding: 5px;
}

.form-icon {
    color: @blue;
    font-size: 60px;

    @media (max-width: 830px) {
        font-size: 50px;
    }

    @media (max-width: 660px) {
        font-size: 40px;
    }
}

.success-bottom {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 0px 25% 60px 25%;
    padding: 25px;
    border-radius: 0px 0px 30px 30px;
    background-color: white;
    font-weight: 700;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 630px) {
        margin: 0px 15% 60px 15%;
    }

    @media (max-width: 500px) {
        margin: 0px 5% 60px 5%;
    }

    @media (max-width: 380px) {
        margin: 0px 0% 40px 0%;
    }
}


#success-message {
    font-weight: 600;
    color: @blue;
    font-size: 36px; 
    padding: 15px;
    margin-top: -20px;

    @media (max-width: 660px) {
        font-size: 34px; 
    }
}


// submission animation
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-in {
    animation: fadeIn 1s ease-out forwards;
}

.fade-out {
    animation: fadeOut 1s ease-out forwards;
}


// submission loader

.loader {
    width: 90px;
    height: 14px;
    --c:#fff 92%,#0000;
    background: 
      radial-gradient(circle closest-side,var(--c)) calc(100%/-4) 0,
      radial-gradient(circle closest-side,var(--c)) calc(100%/4)  0;
    background-size:calc(100%/2) 100%;
    animation: l14 1.5s infinite;
  }
  @keyframes l14 {
      0%   {background-position: calc(100%/-4) 0    ,calc(100%/4) 0}
      50%  {background-position: calc(100%/-4) -14px,calc(100%/4) 14px}
      100% {background-position: calc(100%/4)  -14px,calc(3*100%/4) 14px}
  }

  
