@import '../variables.less';

.footnote {
    position: relative;
    height: 180px;
    width: 100%;
    background-color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
}

.footer-github-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.icon{
    width: 40px;
}

.icon:hover {
    cursor: pointer;
    width: 50px;
}

.copyright {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}
