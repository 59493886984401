@import '../variables.less';

.education-container {
    width: 85%;
    margin: auto;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

#education-title {
    text-align: left;
    margin-top: -5px;
    margin-bottom: 15px;
}


.education-header {
    margin-top: 20px;
    margin-bottom: -5px;
    font-size: 24px;
}

.education-subheading {
    margin-top: 50px;
    margin-bottom: -5px;
    font-size: 18px;
}

#degree {
    font-size: 18px;
    font-weight: bold;
}

#date {
    font-weight: 400;
}

.education-container ul {
    list-style: none;
    padding-left: 0;
}

.education-container ul li {
    margin-top: 3px;
    margin-bottom: 3px;
}

.specialization-icon {
    padding-right: 8px;
}


.education-content {
    display: flex;
    height: 525px;
    gap: 25px;
}

.education-info {
    flex: 1;
    min-height: 400px;
}

.education-map {
    position: relative;
    flex: 1;
    height: 475px;
    position: relative;
    border-radius: 25px;
    margin-top: 20px;

    cursor: default; 

}
.education-map canvas {
    cursor: default !important;
}

.circle-button {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    z-index: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #dadddb;
    box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.4);
}

.circle-button:hover {
    background-color: #f3f4f4;
}

.boxData-style {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    padding: 10px;
    font-size: 14px;
    z-index: 1;
}

.boxData-style::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.8);
    transform: translateX(-50%);
}



// mobile layout

@media (max-width: 950px) {

    .education-header {
        margin-top: 10px;
        margin-bottom: -10px;
    }

    .education-info {
        min-height: auto;
        max-height: 200px;
        width: 100%;
        display: flex;
    }

    .education-content {
        flex-direction: column;
        height: 600px;
        gap: 0px;
    }

    .education-subheading {
        margin-top: 10px;
        margin-bottom: -10px;
    }

    .info-column {
        flex: 1;
        max-height: 200px;
    }

    .right-info {
        margin-left: 50px;
    }
}



@media (max-width: 680px) {

    #university {
        font-size: 20px;
    }

    #degree,
    #scholarship,
    #specializations,
    #concentration {
        font-size: 16px;
    }

    #date,
    .education-container ul li {
        font-size: 14px;
    }
}

@media (max-width: 520px) {

    .right-info {
        margin-left: 20px;
    }

}


@media (max-width: 520px) {

    #university {
        font-size: 16px;
    }

    #degree,
    #scholarship,
    #specializations,
    #concentration {
        font-size: 14px;
    }

    #date,
    .education-container ul li {
        font-size: 12px;
    }

}


@media (max-width: 380px) {

    .education-info {
        max-height: 220px;
    }

}
