@import './variables.less';


* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    -ms-overflow-style: none;
}

.page {
    position: relative;
    height: 750px;
    width: 100%;
    padding: 50px;
}

.page:first-of-type {
    height: 670px;
}

@media screen and (max-width: 865px) {
    .page:first-of-type {
        height: 610px;
    }
}

@media screen and (max-width: 579px) {
    .page:first-of-type {
        height: 570px;
    }
}
@media screen and (max-width: 510px) {
    .page:first-of-type {
        height: 550px;
    }
}

@media screen and (max-width: 380px) {
    .page:first-of-type {
        height: 520px;
    }
}


// experience page
.page:nth-child(5) {
    height: 900px;
}


@media screen and (max-width: 910px) {
    .page:nth-child(5) {
        height: 950px;
    }
}

@media screen and (max-width: 815px) {
    .page:nth-child(5) {
        height: 1000px;
    }
}

@media screen and (max-width: 650px) {
    .page:nth-child(5) {
        height: 1050px;
    }
}



@media screen and (max-width: 639px) {
    .page:nth-child(5) {
        height: 1460px;
    }
}



@media screen and (max-width: 490px) {
    .page:nth-child(5) {
        height: 1500px;
    }
}

@media screen and (max-width: 430px) {
    .page:nth-child(5) {
        height: 1560px;
    }
}

@media screen and (max-width: 385px) {
    .page:nth-child(5) {
        height: 1600px;
    }
}

@media screen and (max-width: 365px) {
    .page:nth-child(5) {
        height: 1660px;
    }
}

@media screen and (max-width: 353px) {
    .page:nth-child(5) {
        height: 1760px;
    }
    
}


/* Media query for mobile screens */
@media screen and (max-width: 770px) {
    .page {
        padding: 50px 0px;
    }
}



.last-page {
    height: 750px;
}



.grey-bg {
    background-color: @black;
}

.white-bg {
    background-color: white;
}


#canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
