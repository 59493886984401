@import '../variables.less';

.search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 180px;
    position: relative;
}

.search-bar {
    // font-size: 14px;
    width: 160px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 11px;
    margin-right: 12.5px;
    padding-left: 30px;
    border: none;
    position: relative;
}

.search-icon {
    position: absolute;
    left: 10px;
    z-index: 5;
    color: black;
}

.search-bar:hover {
    cursor: text;
}

.search-bar:focus {
    outline: none;
    box-shadow: none;
}

.search-button:hover {
    background-color: #e8e8e8;
}



/* collapse into magnifying glass */
@media (max-width: 900px) {
    .search-container {
        position: relative;
        width: 50px;
    }

    .search-icon:hover {
        cursor: pointer;
        color: @blue;
    }

    .search-bar {
        position: absolute;
        right: 0;
        width: 40px;
        padding: 0 30px 0 10px;
        transition: width 0.3s ease-in-out;
    }

    .search-bar::placeholder {
        color: grey;
    }

    .search-bar:focus {
        width: 160px;
        padding: 0 30px 0 10px;
    }

    .search-icon {
        position: absolute;
        right: 10px;
    }
}
