@import '../variables.less';

// main page set up
#skills-title {
    text-align: left;
    margin-top: -5px;
    margin-bottom: 30px;
    color: white;
}

.skills-container {
    width: 85%;
    margin: auto;
    height: 660px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.skill-icon {
    width: 33.33%;
    height: 100%;
    background-color: @blue;
    border-radius: 8px 0px 0px 8px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.8rem;
}


.skill-text {
    padding-left: 15px;
    color: @black;
    font-weight: 900;
    font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
    width: 66.66%;
    height: 100%;
    background-color: white;
    border-radius: 0px 8px 8px 0px;
    left: 33.33%;
    display: flex;
    align-items: center;

    @media (max-width: 580px) {
        padding-left: 7.5px;
        padding-right: 4px;
    }
}





.grid-skill-page-wrapper {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, auto);
    gap: 20px 80px; /* Gap between cells */

    @media (max-width: 1150px) {
        gap: 20px 40px;
    }

    @media (max-width: 1050px) {
        gap: 20px 20px;
    }

}

.grid-cell {
    position: relative;
}

.skill-icon,
.skill-text {
    position: absolute;
    top: 0;
    bottom: 0;
}



@media (max-width: 980px) {
    .grid-skill-page-wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 7.5px 25px;

        @media (max-width: 580px) {
            gap: 10px 10px;
        }
    }

    .skill-icon {
        width: 40%;
        font-size: 2rem;
    }

    .skill-text {
        width: 60%;
        left: 40%;
    }
}





.grid-skill-page-wrapper .grid-cell {
    &:nth-child(3n+1) .skill-icon {
        background-color: @black;
        border: 2px solid white;
    }
    &:nth-child(3n+2) .skill-icon {
        background-color: @black;
        border: 2px solid white;
    }
    &:nth-child(3n+3) .skill-icon {
        background-color: @black;
        border: 2px solid white;
    }
}
