@import '../variables.less';

// project page set up
#projects-container {
    width: 85%;
    margin: 0px auto;
    align-items: flex-start;
    justify-items: start;
    overflow: auto;
}

#projects-title {
    text-align: left;
    margin-top: -5px;
    margin-bottom: 15px;
}

.grid-page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg-color {
    color: white;
    text-align: left;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr repeat();
    grid-template-rows: repeat(3, 170px);
    grid-gap: 20px;
    grid-template-areas:
        "one one two two three three"
        "four four five five six six"
        "seven seven eight eight nine nine";
    transition: transform 0.5s ease, opacity 0.5s ease;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}

@media (max-width: 1300px) {
    .grid-container {
        grid-template-rows: repeat(4, 170px);
        grid-template-areas:
            "one one two two"
            "three three four four"
            "five five six six";
    }
}

@media (max-width: 700px) {
    .grid-container {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 175px);
        grid-gap: 10px;
        grid-template-areas:
            "one"
            "two"
            "three";
    }
}

@media (max-width: 570px) {
    .grid-container{
        grid-gap: 20px;
        grid-template-rows: repeat(3, 180px);
    }
}
@media (max-width: 470px) {
    .grid-container{
        grid-template-rows: repeat(3, 175px);
    }
}



// pagination
#pagination-container {
    display: flex;
    justify-content: space-between;
    width: 150px;
    height: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
}

@media (max-width: 570px) {
    #pagination-container {
        width: 45%;
        height: 40px;
    }
}

.page-num,
.page-number,
.page-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:hover {
        cursor: pointer;
    }
}

.page-button {
    flex: 2;
    font-weight: bold;
}

.blank-button {
    background-color: white;
    color: #C4C4C6;

    &:hover {
        cursor: default;
        color: #C4C4C6;
    }
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2.5px solid @black;
    border-radius: 50%;
    padding: 10px;
}


.icon-wrapper-grey {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2.5px solid #C4C4C6;
    border-radius: 50%;
    padding: 10px;
}




// mobile pagination
.mobile-page-circle {
    @base-height: 22px;
    @base-width: 22px;

    height: @base-height;
    width: @base-width;
    background-color: #e3e3e3;
    border-radius: 50%;
    display: inline-block;
    margin: 8px 5px;
    cursor: pointer;
    opacity: 75%;

    &:hover {
        height: @base-height * 1.15;
        width: @base-width * 1.15;
    }
}


.mobile-page-circle.active {
    background-color: #575757;
    opacity: 75%;
}

#pagination-container.mobile {
    border-radius: 20px;
}

#pagination-container.mobile {
    margin-bottom: 10px;

    @media (max-width: 470px) {
        margin-bottom: 20px;
    }
}




// grid areas
.one, .two, .three, .four, .five, .six, .seven, .eight, .nine{
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 3px solid #1B1B1D;

    &:hover {
        cursor: pointer;
        background-color: #e3e3e3;
    }
}

.one {
    grid-area: one;
}

.two {
    grid-area: two;
}

.three {
    grid-area: three;
}

.four {
    grid-area: four;
}

.five {
    grid-area: five;
}

.six {
    grid-area: six;
}

.seven {
    grid-area: seven;
}

.eight {
    grid-area: eight;
}

.nine {
    grid-area: nine;
}

@media (max-width: 570px) {
    .one, .two, .three, .four, .five, .six, .seven, .eight, .nine {
        margin: 0px 30px;
    }
    
}





// small sections
.small {
    display: flex;
    justify-content: space-between;
    position: relative;
}


.small .inner-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0%;
    top: 0%;
    right: 0%;
    width: 48%;
}

.small .section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}




//container stuff
#projects-container .top-section {
    color: white;
    background-color: @black;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    // text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px 0px 8px 0px;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

#projects-container .combined-bottom-section {
    color: black;
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
}






/* Chevron icon styles */
.chevron-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 52px;
    color: #1B1B1D;

    &:hover {
        cursor: pointer;
    }
}

.chevron-icon.left {
    left: 15px;
    transform: translateY(-50%) rotate(180deg);

    @media (max-width: 470px) {
        left: 5px;
    }

    @media (max-width: 350px) {
        left: 0px;
    }
}

.chevron-icon.right {
    right: 15px;

    @media (max-width: 470px) {
        right: 5px;
    }

    @media (max-width: 350px) {
        right: 0px;
    }
}

.chevron-icon.grey-chevron {
    color: #e5e5e5;
}



/* animations */
.slide-out-to-left {
    animation-name: slideOutToLeft;
}

.slide-out-to-right {
    animation-name: slideOutToRight;
}

.slide-in-from-left {
    animation-name: slideInFromLeft;
}

.slide-in-from-right {
    animation-name: slideInFromRight;
}

@keyframes slideOutToLeft {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 1;
    }
}

@keyframes slideOutToRight {
    0% {
        transform: translateX(0%);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 1;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 1;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
